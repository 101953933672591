"use strict";

import * as Coze from '../pkg/coze_all~fv=00000000.min.js'

// Use Coze's CanonicalHash for Hashing JS things.  
export {
	HashAB,
	HashFile,
	HashFileCallback,
	RandomB64,
	RandomIDB64,
}


/**
@typedef {import('../../../pkg/cozejs/typedef.js').Hsh}    Hsh
@typedef {import('../../../pkg/cozejs/typedef.js').Dig}    Dig
 */

/**
HashAB hashes an ArrayBuffer.
Non hash algs (ES256) are converted to hash algs (SHA-256).
@param  {ArrayBuffer} ab             Javascript ArrayBuffer
@param  {Hsh}         [alg=SHA-256]  Coze hash alg.
@return {Dig}
 */
async function HashAB(ab, alg) {
	if (isEmpty(alg)) {
		alg = "SHA-256"
	}
	return Coze.ArrayBufferTo64ut(await crypto.subtle.digest(Coze.HashAlg(alg), ab))
}

/**
HashFile hashes a blob synchronously.
Non hash algs (ES256) are converted to hash algs (SHA-256).
@param  {Blob} file            Blob
@param  {Hsh}  [alg=SHA-256]   Coze hash alg.
@return {Dig}
 */
async function HashFile(file, alg) {
	if (isEmpty(alg)) {
		alg = "SHA-256";
	}
	alg = Coze.HashAlg(alg);
	let buffer = await file.arrayBuffer();
	let ab = await crypto.subtle.digest(alg, buffer);
	return await Coze.ArrayBufferTo64ut(ab);
};

/** 
HashFileCallback hashes a blob asynchronously.
Non hash algs (ES256) are converted to hash algs (SHA-256).
@param  {Blob}     file           blob
@param  {Hsh}      [alg=SHA-256]  Coze hash alg.
@param  {function} [callback]     Optional callback
@return {Dig}
 */
async function HashFileCallback(file, callback) {
	if (isEmpty(alg)) {
		alg = "SHA-256";
	}
	alg = Coze.HashAlg(alg);

	let reader = new FileReader();
	reader.readAsArrayBuffer(file);
	reader.onloadend = async function () {
		let ab = await crypto.subtle.digest("SHA-256", reader.result);
		let digest = await Coze.ArrayBufferTo64ut(ab);
		if (!isEmpty(callback)) {
			return callback(digest);
		}
		return digest;
	}
};

/** 
RandomB64 returns a random B64 encoded string.
@param  {number} [num] Number of random bytes. Defaults to 32 (32 for 256 bits of entropy).
@return {B64}
 */
async function RandomB64(num) {
	if (isEmpty(num)) {
		num = 32
	}
	let randomArray = new Uint8Array(num)
	window.crypto.getRandomValues(randomArray)
	return Coze.ArrayBufferTo64ut(randomArray)
}

/** 
RandomIDB64 returns a random B64 encoded double digest.
@param  {Alg} [alg] Alg used for hashing algo. Defaults to SHA-256.
@return {B64}
 */
async function RandomIDB64(alg) {
	if (isEmpty(alg)) {
		alg = "SHA-256"
	}
	let randomArray = new Uint8Array(Coze.HashSize(alg))
	window.crypto.getRandomValues(randomArray)
	return Coze.ArrayBufferTo64ut(await crypto.subtle.digest(Coze.HashAlg(alg), await crypto.subtle.digest(Coze.HashAlg(alg), randomArray)))
}