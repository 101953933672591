"use strict";

/*
We recommend using hex when dealing with the arraybuffer (Uint8Array).  Why
Hex? Because "RFC 4648 base64 (maybe URI safe and maybe padded or truncated)
cannot represent all numbers without accounting for _right_ padding. For
example Javascript's base64 encoding cannot represent 2^256  in a _left
padding paradigm_ (like decimal, hex, dozenal, etc...) because Javascript's
base64 is in 43 6 bit buckets, or 258 bits. 

Hex avoids this because it's 4 bit buckets fit into bytes and is left padded.  
*/

const Base10 = "0123456789";
const Base16 = "0123456789ABCDEF";
const Base16Lower = "0123456789abcdef";

///////////////////////
// RFC 4648 base64s
///////////////////////
// RFCBase64Unsafe is a URI unsafe base 64 alphabet, the "default" for RFC 4648. 
const RFCBase64Unsafe = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
// RFCBase64Uri is RFC 4648 URI safe base 64 alphabet.  
const RFCBase64Uri = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";

// ASCII Extended control and non printable characters.
const ASCIIExtCTRLNPChars = String.fromCharCode(
	// First two rows and space
	0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
	22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
	// Row 9, 10, NBSP, and soft hyphen
	127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141,
	142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156,
	157, 158, 159, 160, 173
);


// Start: Remove when merging with Zami's BaseConvert 


export {
	AB,
};

// This section includes all of Cyphr.me's proprietary alphabets, as well as any
// enums that should not ever be pushed up or down stream.

///////////////////////
// (Patent Pending) Cyphr.me, Cypherpunk LLC Alphabets
///////////////////////
// (Patent Pending) Cyphr.me, Cypherpunk LLC, BASE37
// Base37 are URI friendly base 37.
const BASE37 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-";

// (Patent Pending) Cyphr.me, Cypherpunk LLC, BASE38-QR-URI-UNRESERVED
const BASE38 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-.";

// (Patent Pending) Cyphr.me, Cypherpunk LLC, BASE45 The full QR-URI alphabet
const BASE45 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-/$%*+: .";

// (Patent Pending) Cyphr.me, Cypherpunk LLC, Base64 (**NOT THE RFC "base64"**) 
const Base64 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_";

// (Patent Pending) Cyphr.me, Cypherpunk LLC, Base64 Base66-uri-unreserved.
// Named "Unreserved" since it is not a truncation of Base191.  If it were, it would simple be "Base66Uri".
const Base66UriUnreserved = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_.~";

// ((Patent Pending) Cyphr.me, Cypherpunk LLC, Full Base191 alphabet
// Note the escaping '\' character before the double quote char '"'
const Base191 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_.~!\"$'()*+,:;<=>?@[\]^`{|}¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁ ÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ#%&/ "

// AB must be last, for all alphabets must be defined first.
// Defined Alphabets.  AB = "alphabets"
var AB = {
	ASCIIExtCTRLNPChars: ASCIIExtCTRLNPChars,
	Base10: Base10,
	Base16: Base16,
	Base16Lower: Base16Lower,
	BASE37: BASE37,
	BASE38: BASE38,
	BASE45: BASE45,
	Base64: Base64,
	Base66UriUnreserved: Base66UriUnreserved,
	Base191: Base191,
	RFCBase64Unsafe: RFCBase64Unsafe,
	RFCBase64Uri: RFCBase64Uri,
};


// End: Non merge section