"use strict";

import * as Coze from '../pkg/coze_all~fv=00000000.min.js'
import * as Crypto from './crypto.js'
// import {
// 	IsCyphrmeDigest
// } from '../app/cyphrme.js';

export {
	AnticounterfeitCanon,

	NewAnticounterfeit,
	AppendToObject,
};

/**
@typedef {import('../../../pkg/cozejs/typedef.js').B64}     B64
@typedef {import('../../../pkg/cozejs/typedef.js').Coze}    Coze
@typedef {import('../../../pkg/cozejs/typedef.js').Dig}     Dig
@typedef {import('../../../pkg/cozejs/typedef.js').Pay}     Pay
@typedef {import('../../../pkg/cozejs/typedef.js').Alg}     Alg
@typedef {import('../app/ac_gen.js').ACOptions}             ACOptions
@typedef {import('../page/ac_gen_pj.js').PJ}                PJ
*/

// Required fields
const AnticounterfeitCanon = ["alg", "iat", "tmb", "typ", "id"]

/**
NewAntiCounterfeit generates a new basic anti-counterfeit.  Accepts an
optional existing AC, and fields are added/existing fields are used where
applicable.  
Since seed is not currently preserved, so on random generated ID's there is
no seed, and if Seed is needed, id needs to be the digest of seed.  
Throws error when given an ID in pay, but is not a valid cyphr.me digest.

Returns in this form:
{
 "pay": {
  "alg": "ES256",
  "iat": 1686263707,
  "tmb": "dfzR4DYK8o36trYoMGbOEdvdLrjFjcdku0tdZ6h4cok",
  "typ": "cyphr.me/ac/create",
  "id": "K5RyJ04c2gb5JBMQ-9Icr5tTJGlgBV8XaYAAEUMxdMA",
 },
 "sig": "O2Ovsbc5dFHq6e9OI42KACl-1x757o0r0130NGlMbTRHumtI_azeNIQ_pDpsJOnm2UCMm7-yWQeSJ4695tid1w"
}

@param   {CozeKey} cozeKey     CozeKey
@param   {AC}      [ac]        Optional existing AC.  (coze.pay)
@returns {AC}
@throws  {error}
*/
async function NewAnticounterfeit(cozeKey, ac) {
	// console.debug(pay, isEmpty(pay))
	if (isEmpty(ac)) {
		ac = {}
	}

	// To avoid overwriting/modifying the original reference copy.
	let pay = {
		...ac
	}
	pay.alg = cozeKey.alg
	pay.iat = Now()
	pay.tmb = cozeKey.tmb
	pay.typ = "cyphr.me/ac/create"
	if (!("id" in pay)) {
		pay.id = await Crypto.RandomIDB64(cozeKey.alg)
	}

	// console.log(pay)
	return await Coze.Sign({
		"pay": pay
	}, cozeKey, AnticounterfeitCanon)
}



/**
AppendToObject appends `objToAppend` to `obj` without loosing reference to the
original object.  Making a copy of the original `obj` requires assignment ("=",
e.g. o = {...o}), and assignment causes reference loss. // TODO write test to
make sure this is the case. For global stateful objects, keeping the reference
may be critical, as other modules lose reference when assignment is made.

The spread operator necessitates assignment, which is what loses reference.
Don't use the copy syntax if needing the same reference, for example:
```
ACs = {
	...ACs,
	...moreACs
}
```

TODO if tests confirm our suspicion that assignment is the problem, add test to
doc and move this function to helpers.

TODO consider renaming to AppendObjectToObject
@param   {object}  obj
@param   {object}  objToAppend
@returns {void}
*/
function AppendToObject(obj, objToAppend) {
	for (let key in objToAppend) {
		obj[key] = objToAppend[key]
	}
}