"use strict"
import * as BASECNV from './base_convert.js'
import {
	AB
} from "./enum.js"

export {
	// Hex
	BaseToHex,
	HexPadded,
	HexToS,
	SToHex,
	HexToUb64p,
	HexTob64ut,
	ArrayBufferToHex,
	HexToArrayBuffer,

	// RFC 4648 base64
	B64ToHex,
	UB64pToS,
	B64tToP,
	B64utToS,
	B64utToArrayBuffer,
	ArrayBufferTo64ut,
	B64ToUint8Array,
	URISafeToUnsafe,
	URIUnsafeToSafe,
	SToB64ut,
	SToUB64p,
}


/**
@typedef {import('./typedef.js').Hex}                   Hex
@typedef {import('./typedef.js').ub64}                  ub64
@typedef {import('./typedef.js').b64ut}                 b64ut
@typedef {import('./typedef.js').ub64}                  ub64
 */

/** BaseToHex converts base to Hex.
@param  {string} input         Input string.
@param  {string} inAlph        Input alphabet (i.e. 0123456789ABCDEF)
@param  {Hex}
 */
function BaseToHex(input, inAlph) {
	if (input === null || input === "" || input.length === 0) {
		return ""
	}
	if (inAlph !== Base16) {
		input = BASECNV.BaseConvert(input, inAlph, Base16)
	}
	if (input.length % 2 === 1) {
		input = input.padStart(input.length + 1, "0")
	}
	return input // Hex is always padded.
}


// String to ASCII (UTF-8) binary HEX string
function SToHex(input) {
	// console.debug(input)
	if (typeof input != 'string') {
		throw new TypeError('Input is not a string. Given type: ' + typeof input)
	}
	return input.split("").reduce((hex, c) => hex += c.charCodeAt(0).toString(16).toUpperCase().padStart(2, "0"), "")
}

// ASCII (UTF-8) binary HEX string to string
function HexToS(input) {
	if (typeof input != 'string') {
		throw new TypeError('input is not a string')
	}
	if (isEmpty(input)) {
		return "" // empty is a valid input.
	}
	return input.match(/.{1,2}/g).reduce((acc, char) => acc + String.fromCharCode(parseInt(char, 16)), "")
}

/**
HexPadded accepts input, input alphabet, and alg and returns Hex.  Alg is
used to enforce padding. 
@param    {string} input       String. Number being converted.
@param    {string} inputBase   String. Input alphabet for input.
@param    {string} alg         String. Alg for specifying length of pad.
@returns  {string}             Base16 padded to alg's specified length.
@throws   {error}              Returns error on unsupported algs.
 */
function HexPadded(input, inputBase, alg) {
	let hex = BASECNV.BaseConvert(input, inputBase, AB.Base16)
	switch (alg) {
		case 'ES256':
			return hex.padStart(64, "0")
		case "ES384":
			return hex.padStart(96, "0")
		case "ES512":
			return hex.padStart(128, "0")
		default:
			throw new Error("base_convert.HexPadded: unsupported alg")
	}
}


////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////
// RFC 4648 "base64"s
////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////

// There are several base 64's that we use/define.  There are four permutations
// of the RFC 4648 base64s and one Cyphr.me base 64.  "base 64", with a space,
// refers to any encoding with a 64 character alphabet.  RFC base64 has a
// different alphabet than Cyphr.me Base64 (enumerated above).
//
// RFC base64s use bucket encoding.  Cyphr.me Base64 uses
// radix/arbitrary/natural encoding method.  We usually say "arbitrary" but "radix
// conversion" or "natural" is appropriate.
//
// Also note, the RFC for URI safe characters says "URI" and not "URL"
// (https://datatracker.ietf.org/doc/html/rfc3986#section-2.3), "Characters that
// are allowed in a URI (...) are called unreserved.".  We consider "URL" and
// "URL safe" incorrect.
//
// "Truncated" is the preferred term since implementations add padding and then
// remove it, so the unneeded padding is "truncated".  (See JOSE as an example
// explicitly removing the added padding)
//
//   1. ub64p - RFC 4648 URI unsafe base64 padded.
//   2. ub64t - RFC 4648 URI unsafe base64 truncated (no padding).  
//   3. b64up - base64 URI safe padded.
//   4. b64ut - base64 URI safe truncated.
//   5. Base64 - Note the upper case "B".  Uses the "divide by radix" encoding
//      method and a different alphabet. Cyphr.me defined, not RFC 4648 defined.  
//
// Why do we need work with the "Unsafe base 64 padded" (ub64p)?
//
// JOSE's base64 is b64ut which is right padded, so standard base conversion
// cannot be used "out of the box".  Also, Javascript's `atob` is the most
// efficient way to work with base64 even though variant may be used by some
// applications.


/**
HexToUb64p is Hex to RFC ub64p.
@param   {Hex}   Hex
@returns {ub64}          ub64 RFC 4648 URI unsafe base 64 padded.
 */
function HexToUb64p(Hex) {
	// console.debug(Hex)
	if (Hex.length == 0) {
		return ""
	}
	let bytes = Hex.match(/\w{2}/g).map(function (a) {
		return String.fromCharCode(parseInt(a, 16))
	}).join("")
	return btoa(bytes)
}


/**
SToB64ut encodes a string as base64 URI truncated string.
"String to base64 uri truncated"
@param   {string} string 
@returns {b64ut}
 */
function SToB64ut(string) {
	return btoa(string).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

/**
B64utToS takes a b64ut string and decodes it back into a string.
@param   {b64ut} string 
@returns {string}
 */
function B64utToS(string) {
	// atob doesn't care about the padding character '='
	return atob(string.replace(/-/g, '+').replace(/_/g, '/'))
}


/**
SToUB64p takes a string and encodes it into a unsafe Base64 string.
"String to Unsafe Base64"
@param   {string}  string
@returns {ub64}    Unsafe Base64 string
 */
function SToUB64p(string) {
	return btoa(string)
}

/**
UB64pToS takes an unsafe base64 string and decodes it back into a string.
"Unsafe base64 padded to String"
@param   {ub64}   string Unsafe base64 padded string
@returns {string}
 */
function UB64pToS(string) {
	return atob(string)
}

/**
URIUnsafeToSafe converts any URI unsafe string to URI safe.
@param   {string} ub64t 
@returns {string} b64ut 
 */
function URIUnsafeToSafe(ub64) {
	return ub64.replace(/\+/g, '-').replace(/\//g, '_')
}

/**
URISafeToUnsafe converts any URI safe string to URI unsafe.  
@param   {string} b64ut 
@returns {string} ub64t
 */
function URISafeToUnsafe(ub64) {
	return ub64.replace(/-/g, '+').replace(/_/g, '/')
}


/**
B64tToP (truncated to padded) takes any base64 truncated string and adds padding
if appropriate.  
@param   {string} b64t 
@returns {string} base64p
 */
function B64tToP(b64t) {
	var padding = b64t.length % 4
	switch (padding) {
		case 0:
			return b64t
		case 1:
			// malformed input, can only 0, 2, 3, or 4 characters, never 1.  
			console.error("input is invalid b64t.")
			return
		case 2:
			return b64t + "=="
		case 3:
			return b64t + "="
	}
}






// STOLEN:

////////////////////////////////////////////////////
////////////////////////////////////////////////////
// RFC 4648 "base64"s
////////////////////////////////////////////////////
////////////////////////////////////////////////////
// Encodings have two features:
// 1. An alphabet.
// 2. A conversion method.  
//
// RFC 4648 "base64" is a bucket convert encoding method with a specific alphabet.  
// 
// There are several base 64's that we use in two classes:
//   1. Unsafe base64.  We call it `ub64`.
//   2. URL Safe base64 (base64 url).  We call it `b64u` or `base64url`.
//   3. base64 url safe truncated.  We call it `b64ut`. Padding character "="
//      removed.  
//   4. Unsafe base64 truncated isn't a thing.  Why?  Use b64ut instead.  
//   5. Base64, with an upper case "B", has a different alphabet and uses the
//      iterative divide by radix conversion method and is not a bucket
//      conversion method.  (Cyphr.me defined, not RFC 4648 defined.)  NOT IN
//      THIS file. 
//
// NOTE: RFC 4648 uses the lower case "base64" to refer to it's encoding method.
// The casing and spacing is important!  The generic "base 64" with a space is
// used to refer to any encoding system that has a 64 character alphabet.  



/**
B64ToHex takes any RFC 4648 base64 to Hex.
@param    {string} b64        Any RFC 4648 base64.
@returns  {Hex}
 */
function B64ToHex(b64) {
	let ub64 = URISafeToUnsafe(b64)
	const raw = atob(ub64)
	let result = ''
	for (let i = 0; i < raw.length; i++) {
		const hex = raw.charCodeAt(i).toString(16).toUpperCase()
		result += (hex.length === 2 ? hex : '0' + hex)
	}
	return result
}


/**
HexTob64ut is hHx to "RFC 4648 base64 URL Safe Truncated".  
@param   {Hex}    hex    Hex.
@returns {b64ut}
 */
async function HexTob64ut(hex) {
	let ab = await HexToArrayBuffer(hex)
	let b64ut = await ArrayBufferTo64ut(ab)
	return b64ut
}


/**
ArrayBufferTo64ut Array buffer to b64ut.
@param   {ArrayBuffer}  buffer 
@returns {b64ut}
 */
function ArrayBufferTo64ut(buffer) {
	var string = String.fromCharCode.apply(null, new Uint8Array(buffer))
	return base64t(URIUnsafeToSafe(btoa(string)))
}


/**
B64utToArrayBuffer takes a b64 (truncated or not truncated, padded or not
padded) UTF-8 string and decodes it to an ArrayBuffer.
@param   {B64} string 
@returns {ArrayBuffer}
*/
function B64utToArrayBuffer(string) {
	// atob doesn't care about the padding character '='
	return B64ToUint8Array(string).buffer
}


/**
B64ToUint8Array takes a b64 string (truncated or not truncated, padded or not
padded) and decodes it back into a string.
@param   {B64}          string 
@returns {Uint8Array}
*/
function B64ToUint8Array(string) {
	// Make sure that the encoding is canonical.  See issue "Enforce Canonical
	// Base64 encoding" https://github.com/Cyphrme/Coze/issues/18. Alternatively
	// to this method, we could write our own encoder as Mozilla suggests.
	// https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
	string = string.replace(/-/g, '+').replace(/_/g, '/')

	let reencode = btoa(atob(string)).replace(/=/g, '')
	if (reencode !== string) {
		throw new Error('Non-canonical base64 string')
	}

	// atob doesn't care about the padding character '=', but does not like URI
	// encoding.  
	return Uint8Array.from(atob(string), c => c.charCodeAt(0))
}


/**
HexToArrayBuffer converts string Hex to ArrayBuffer.
@param   {Hex}           Hex
@returns {ArrayBuffer}
 */
async function HexToArrayBuffer(hex) {
	if (hex === undefined) { // undefined is different from 0 since 0 == "AA"
		return new Uint8Array().buffer
	}

	if ((hex.length % 2) !== 0) {
		throw new RangeError('HexToArrayBuffer: Hex is not even.')
	}

	var a = new Uint8Array(hex.length / 2)
	for (var i = 0; i < hex.length; i += 2) {
		a[i / 2] = parseInt(hex.substring(i, i + 2), 16)
	}

	return a.buffer
}



/**
ArrayBufferToHex accepts an ArrayBuffer and returns  Hex. Taken from
https://stackoverflow.com/a/50767210/1923095
@param   {ArrayBuffer} buffer       ArrayBuffer.
@returns {Hex}
 */
async function ArrayBufferToHex(buffer) {
	return [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, "0")).join('').toUpperCase()

	// Alternatively:
	// let hashArray = Array.from(new Uint8Array(digest)) // convert buffer to byte array
	// let hexHash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}


// TODO test this.
// TODO not in use.
async function ArrayBufferToBigInt(buffer) {
	// let hex = ArrayBufferToHex()

	let result = 0n
	for (let i = 0; i < bytes.length; i++) {
		result = (result << 8n) + BigInt(bytes[i])
	}
	return result
}

///////////////////
// Helpers
///////////////////

/**
uriUnsafeToSafe converts any URI unsafe string to URI safe.  

TODO not in use
@param   {string} ub64t 
@returns {string} b64ut 
 */
function uriUnsafeToSafe(ub64) {
	return ub64.replace(/\+/g, '-').replace(/\//g, '_')
}

/**
base64t removes base64 padding if applicable.   
@param   {string} base64 
@returns {string} base64t
 */
function base64t(base64) {
	return base64.replace(/=/g, '')
}