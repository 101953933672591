"use strict"

export {
	Authority,
	Root,
	Noun,
	Verb,
	Action,
	Child,
	EveryPathParse,
	EveryPathToString,
	StripHTTPS,
}


/**
EveryPathParse parses an Everything typ or a URI into it's various 
EveryPath components. A typ is considered to be a 'full path', and should
contain all components of an EveryPath.
An EveryPath does not require every component to be fulfilled, in order to be
useful, but rather to be considred a 'full path'.

TODO EveryPathParse should only be used for full path.
@param   {string}     typ   Everything typ or uri.
@returns {EveryPath}  ep    Object with various components parsed out.
 */
function EveryPathParse(typ) {
	let ep = {}
	ep.auth = Authority(typ)
	ep.root = Root(typ)
	ep.Noun = Noun(typ)
	ep.verb = Verb(typ)
	ep.action = Action(typ)
	ep.child = Child(typ)
	return ep
}


/**
StripHTTPS accepts a uri string, and returns an array of path parts, separated
by `/` with the uri stripped of http:// and https:// (if applicable).
@param   {string}    uri
@returns {string[]}
 */
function StripHTTPS(uri) {
	let re = new RegExp('^http:\/\/|^https:\/\/')
	return uri.replace(re, "").split("/")
}


/**
EveryPathToString takes an EveryPath object, and return a reconstructed
uri/typ. (e.g. auth/noun/verb)
@param   {EveryPath}  ep    Parsed EveryPath object. Auth, Noun, and Verb must be present before calling.
@returns {string}           Reconstructed typ/uri.
 */
function EveryPathToString(ep) {
	return ep.auth + "/" + ep.noun + "/" + ep.verb
}


/**
Authority returns the authority.
@param   {string}     typ   Everything typ or uri.
@returns {string}     auth  First component of the typ/uri (aka host).
 */
function Authority(typ) {
	return StripHTTPS(typ)[0]
}


/**
Root returns the root.
@param   {string}     typ   Everything typ or uri.
@returns {string}     root  Component directly after the authority.
 */
function Root(typ) {
	return StripHTTPS(typ)[1]
}


/**
Noun returns the noun.
@param   {string}  typ  Everything typ or uri.
@returns {string}       Components after the authority and before the verb.
 */
function Noun(typ) {
	let parts = StripHTTPS(typ)
	if (parts.length == 2) {
		return parts[1]
	}
	let noun = ""
	for (let i = 1; i < (parts.length - 1); i++) {
		if (i > 1) {
			noun += "/"
		}
		noun += parts[i]
	}
	return noun
}


/**
Verb returns the verb.
@param   {string}  typ   Everything typ or uri.
@returns {string}        Last component.
 */
function Verb(typ) {
	let parts = StripHTTPS(typ)
	return parts[parts.length - 1]
}


/**
Action returns the action.
@param   {string}   typ  Everything typ or uri.
@returns {string}        Component directly after the authority.
 */
function Action(typ) {
	let parts = StripHTTPS(typ)
	let action = ""
	for (let i = 1; i < parts.length; i++) {
		if (i > 1) {
			action += "/"
		}
		action += parts[i]
	}
	return action
}


/**
Child returns the child.
@param   {string}  typ   Everything typ or uri.
@returns {string}        Component that is last unit of the noun.
 */
function Child(typ) {
	let parts = StripHTTPS(typ)
	return parts[parts.length - 2]
}