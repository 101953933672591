"use strict";

export {
	ConcatBuffers,
	SToArrayBuffer,
	ArrayBufferToS,
	ASCIIExtToChiclets,
	BitPerBase,
	ExplodeBytes,
	GoBytesToHex,
	GoBytesToString,
	HexToGoBytesString,
	RemovePad,
	SysCnvToHex,
	ToUTF8Array,
	ArrayBufferToBigInt,
	BigIntToArrayBuffer,

	// General base64 and Hex functions are in base_hex64.js
}

/**
@typedef {import('./typedef.js').Hex}                   Hex
 */

import {
	AB
} from "./enum.js";

var ASCIIExtCTRLNPChars = AB.ASCIIExtCTRLNPChars;

/**
Concatenates the two given array buffers.
@param  {ArrayBuffer} b1 The first buffer.
@param  {ArrayBuffer} b2 The second buffer.
@return {ArrayBuffer} The new ArrayBuffer created out of the two.
 */
function ConcatBuffers(b1, b2) {
	var tmp = new Uint8Array(b1.byteLength + b2.byteLength)
	tmp.set(new Uint8Array(b1), 0)
	tmp.set(new Uint8Array(b2), b1.byteLength)
	return tmp.buffer
}

/**
Converts a string to an ArrayBuffer.   
@param  {string}        string
@return {ArrayBuffer}
 */
async function SToArrayBuffer(string) {
	var enc = new TextEncoder(); // Suppose to be always in UTF-8
	let uint8array = enc.encode(string);
	return uint8array.buffer;
}

/**
Converts an ArrayBuffer to a UTF-8 string.   
@param  {string}        string
@return {string}
 */
async function ArrayBufferToS(ab) {
	var enc = new TextDecoder("UTF-8");
	let s = await enc.decode(ab);
	return s;
}

/**
Returns the exploded byte array from ",".
Example: input = "[255,64,32]" : return = ["255", "64", "32"]
@param   {string}    input     Go Bytes representation as a string.
@returns {string[]}            Array of numbers, as string type.
@throws  {error}               Syntax error.
 */
function ExplodeBytes(input) {
	if (input.charAt(0) != "[" && input.charAt(0) != "{") {
		throw new SyntaxError("not in correct byte format: [255, ...]");
	}
	// Supports trailing comma (but returns it removed).
	if (input.charAt(input.length - 2) == ",") {
		return input.substring(1, input.length - 2).split(",");
	}
	return input.substring(1, input.length - 1).split(",");
}

/**
Convert from a Go Bytes representation, to string.
@param   {string}  input      Go Bytes representation as a string.
@returns {string} 
@throws  {error}              Syntax error.
 */
function GoBytesToString(input) {
	let unicode = "";
	for (let c of ExplodeBytes(input)) {
		unicode += String.fromCodePoint(c);
	}
	return unicode;
}

/**
Convert from a Go Bytes representation, to Hex.
Empty bytes returns "".
@param   {string}  input         Go Bytes representation as a string.
@returns {Hex}
@throws  {error}                 Syntax error.
 */
function GoBytesToHex(input) {
	let hex = "";
	let chunks = ExplodeBytes(input);
	// Empty bytes check
	if (chunks.length == 1 && isEmpty(chunks[0])) {
		return hex;
	}
	for (let c of chunks) {
		hex += parseInt(c).toString(16).toUpperCase().padStart(2, "0");
	}
	return hex;
}

// Returns the Hex string in the Hex line from a correctly formatted SysCnv conversion.
function SysCnvToHex(inputText) {
	let hexLine = inputText.split("\n", 1)[0];
	if (hexLine.substring(0, 4) != "Hex:") {
		throw new SyntaxError('Not in the correct SysCnv format.');
	}
	return hexLine.split(' ', 2)[1];
}

/**
Convert a hex string to a byte array
@param   {Hex}  hex
@returns {string}  Go Bytes representation.
 */
function HexToGoBytesString(hex) {
	for (var bytes = [], i = 0; i < hex.length; i += 2)
		bytes.push(parseInt(hex.substr(i, 2), 16)); // .substring does not return same results
	return "[" + bytes + "]";
}

/**
Get how many bits are needed to represent a particular number base
@param  {number} base The length of the characters of the alphabet (the base,
e.g. for base 64 would be the number 64 and the output would be 6)
@returns {number} The number of bits required to represent the base.  
 */
function BitPerBase(base) {
	var bits = 0;
	var space = 1;

	while (base > space) {
		space = space * 2;
		bits++;
	}
	return bits;
}

// TODO deprecate for something more general. 
// Returns string from the input string, where any control/non-printable characters
// are represented as a chiclet (including space).
// See also Mojibake (https://en.wikipedia.org/wiki/Mojibake)
function ASCIIExtToChiclets(string) {
	let outString = "";
	for (let char of string) {
		if (ASCIIExtCTRLNPChars.includes(char)) {
			outString += '';
			continue;
		}
		outString += char;
	}
	return outString;
}

// TODO Not in use and not working
// RemovePad is a helper function removes pad but not the single zero case.
function RemovePad(input, inAlph) {
	// console.debug("RemovePad", input, inAlph);
	if (input.length == 1) {
		return input;
	}
	// Remove padding characters
	let inPad = inAlph.charAt(0);
	for (var i = 0; i < input.length; i++) {
		if (input.charAt(i) !== inPad) {
			break;
		}
	}
	return input.substring(i);
}


/**
Converts an ArrayBuffer to a UTF-8 string.
@param  {ArrayBuffer} ab
@return {string}
 */
async function ArrayBufferToS(ab) {
	var enc = new TextDecoder("UTF-8")
	return enc.decode(ab)
}

/**
ToUTF8Array accepts a string and returns the utf8 encoding of the string.
https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array
@param   {string}     str     String that is being converted to UTF-8.
@returns {number[]}           Number array returned from the input string.
 */
function ToUTF8Array(str) {
	var utf8 = [];
	for (var i = 0; i < str.length; i++) {
		var charcode = str.charCodeAt(i);
		if (charcode < 0x80) utf8.push(charcode);
		else if (charcode < 0x800) {
			utf8.push(0xc0 | (charcode >> 6),
				0x80 | (charcode & 0x3f));
		} else if (charcode < 0xd800 || charcode >= 0xe000) {
			utf8.push(0xe0 | (charcode >> 12),
				0x80 | ((charcode >> 6) & 0x3f),
				0x80 | (charcode & 0x3f));
		}
		// surrogate pair
		else {
			i++;
			// UTF-16 encodes 0x10000-0x10FFFF by
			// subtracting 0x10000 and splitting the
			// 20 bits of 0x0-0xFFFFF into two halves
			charcode = 0x10000 + (((charcode & 0x3ff) << 10) |
				(str.charCodeAt(i) & 0x3ff));
			utf8.push(0xf0 | (charcode >> 18),
				0x80 | ((charcode >> 12) & 0x3f),
				0x80 | ((charcode >> 6) & 0x3f),
				0x80 | (charcode & 0x3f));
		}
	}
	return utf8;
};

//https://stackoverflow.com/questions/13356493/decode-utf-8-with-javascript
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
function encode_utf8(s) {
	return unescape(encodeURIComponent(s));
};

function decode_utf8(s) {
	return decodeURIComponent(escape(s));
};


/** Converts a Big Endian ArrayBuffer to BigInt.  
@param   {ArrayBuffer}         buffer
@returns {BigInt}         
 */
function ArrayBufferToBigInt(buffer) {
	let result = 0n;
	let a = new Uint8Array(buffer)
	for (let i = 0; i < a.length; i++) {
		result = (result << 8n) + BigInt(a[i]);
	}
	return result;
}

/**
Converts a BigInt to a Big Endian ArrayBuffer.
@param   {size}         int     Number of bytes to pad the ArrayBuffer
@param   {Bigint}       bigInt 
@returns {ArrayBuffer}
 */
function BigIntToArrayBuffer(size, bigInt) {
	const buffer = new ArrayBuffer(size);
	const view = new DataView(buffer);

	do {
		size--;
		view.setUint8(size, Number(bigInt & 0xffn)) // NOTE: Linter puts a space between oxff and n
		bigInt >>= 8n;
	} while (size > 0);
	return buffer;
}